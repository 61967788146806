.welcome-container {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.welcome-card {
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
}

.logo-container {
  margin-bottom: 2rem;
}

.welcome-logo {
  width: 150px;
  height: auto;
}

.welcome-title {
  margin-bottom: 1rem;
  font-size: 2rem;
  color: #333;

}

.company-name {
  color: #00a651;
  font-weight: bold;
}

.welcome-subtitle {
  font-style: italic;
  color: #666;
  font-size: 1.2rem;
} 